<script>
import { GeneralSummary } from 'aether-general-summary-vue2'
import { LUOSummary } from 'aether-luo-summary-vue2'
import { RegistrarSummary } from 'aether-registrar-summary-vue2'
import {QuickContactVerifyModal} from "aether-quickcreate-vue2";
import {
  FlamesClubSummary_Full,
  TicketOfficeSummary_Full,
  DonorEngagementSummary_Full,
} from 'aether-university-development-summary-vue2'
import globals from '../../../globals'

export default {
  components: {
    GeneralSummary,
    LUOSummary,
    RegistrarSummary,
    FlamesClubSummary_Full,
    TicketOfficeSummary_Full,
    DonorEngagementSummary_Full,
    QuickContactVerifyModal
  },
  props: {
    luid: {
      type: String,
      required: true,
    },
    modalData: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      visibilityOptions: localStorage.getItem('userVisibility').split(','),
      activeSummaryPage: localStorage.getItem('appName'),
      opportunityApps: globals.uDevApps,
    }
  },
  methods: {
    handleUserDataUpdated() {
      // console.log("parent component can handle update now");
    },
  },
}
</script>

<template>
  <span>
    <div v-if="activeSummaryPage === 'General'">
      <GeneralSummary />
    </div>
    <div v-else-if="activeSummaryPage === 'LUO'">
      <LUOSummary />
    </div>
    <div v-else-if="activeSummaryPage === 'Registrar'">
      <RegistrarSummary />
    </div>
    <div v-else-if="activeSummaryPage === 'LUOA'">TO DO: Add LUOA Summary</div>
    <!-- built-in header - removing gap at the top -->
    <div
      v-else-if="opportunityApps.find(a => a === activeSummaryPage)"
      style="margin-top: -20px"
    >
      <div v-if="activeSummaryPage === 'Flames Club'">
        <FlamesClubSummary_Full />
      </div>
      <div v-else-if="activeSummaryPage === 'Ticket Office'">
        <TicketOfficeSummary_Full />
      </div>
      <div v-else-if="activeSummaryPage === 'Donor Engagement'">
        <DonorEngagementSummary_Full />
      </div>
    </div>
    <div v-else>
      <LUOSummary />
    </div>

    <QuickContactVerifyModal
      :modalData="modalData"
      @userDataUpdated="handleUserDataUpdated"
    ></QuickContactVerifyModal>
  </span>
</template>

<style scoped>
.v-select {
  max-width: 175px !important;
}
</style>
