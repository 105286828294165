<script>
import {
    mdiAlert,
    mdiContentCopy
} from '@mdi/js';
import ProfileAvatar from '@/layouts/components/action-bar/profile/ProfileAvatar.vue';
import filters from '@/filters.js';
import { Notification, NotificationIcon } from 'aether-notifications-vue2';

import contactService from '../../../services/contact.service';

export default {
    components: { ProfileAvatar, Notification, NotificationIcon },
    filters: {
        notBlank: filters.notBlank,
        date: filters.date,
        yesNo: filters.yesNo
    },
    setup() {
        return {
            icons: {
                mdiAlert,
                mdiContentCopy
            },
        }
    },
    data: function () {

        return {

            getContact: {},
            snackbar: false,
            timeout: 2000,
        }
    },
    async mounted() {
        try {

            if (sessionStorage.getItem('pidm')) {
                this.getContact = await contactService.fetchContact(this.$store);
            }

        } catch (error) {
            this.error = true;
        } finally {
            this.loading = false;
        }

    },
    methods: {
        copyLuid: function (luid) {
            navigator.clipboard.writeText(luid);
            this.snackbar = true;
        }
    }

}

</script>

<template>
    <div class="d-inline-flex mb-4">

        <v-img v-if="getContact.fullphoto" class="rounded-img" :aspect-ratio="1" :src="`${getContact.fullphoto}`" />

        <iconify-icon v-else icon="solar:user-bold" style="font-size: 25px;"></iconify-icon>

        <span class="vertical-align">
            <b>{{ getContact.name }}&nbsp;</b>

            <v-tooltip bottom v-if="getContact.libertyId">
                <template v-slot:activator="{ on, attrs }">
                    <span class="luid" v-bind="attrs" v-on="on" @click="copyLuid(getContact.libertyId)">
                        {{ getContact.libertyId }} <v-icon small color="#0052cc" style="margin-bottom: 4px;">{{ icons.mdiContentCopy }}</v-icon>
                    </span>
                </template>
                <span>Copy LUID</span>
            </v-tooltip>
        </span>

        <v-snackbar color="primary" text top v-model="snackbar" :timeout="timeout">
            Copied {{ getContact.libertyId }} to clipboard
            <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>


<style>
.rounded-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    /* Ensures the image covers the area without distortion */
    float: left;
    margin-right: 5px;
}

.luid {
    cursor: pointer;
    user-select: none;
    font-size: 14px;
}

.vertical-align {
    vertical-align: middle;
}
</style>