import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify.js'
import { Vue2Dragula } from 'vue2-dragula'
import router from './router'
import store from './store'
import globals from './globals'
import injector from 'vue-inject';
import VeeValidate from 'vee-validate';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
// import { aetherServiceLibrary } from 'aether-service-library';
import setupService from './services/setup.service.js'
setupService.setupAllServices();

import 'bootstrap-css-only/css/bootstrap.min.css';
import 'aether-shared-styles/dist/styles.css';

Vue.config.productionTip = false
Vue.config.errorHandler = function (err, vm, info) {
  handleError(err)
}

window.onerror = function (message, source, line, column, error) {
  handleError(message)
}

function handleError(error) {
  const currentPage = window.location.href;

  if (error.toString().includes('InteractionRequiredAuthError')) {
    if (!currentPage.includes("/error") && !currentPage.includes("/login")) {
      localStorage.setItem('shouldRedirect', "false")
      localStorage.setItem("redirectUrl", currentPage);
    }

    window.location.href = window.location.origin + "/error"
  } else {
    throw error
  }
}

// Vue.use(aetherServiceLibrary);
Vue.use(injector);
Vue.use(VeeValidate);
Vue.use(Vue2Dragula)

globals.pidm = sessionStorage.getItem('pidm');
globals.libertyId = sessionStorage.getItem('libertyid');
globals.contactId = sessionStorage.getItem('contactId');

if (!localStorage.getItem("appName")) {
  localStorage.setItem("appName", "LUO")
}

switch (window.location.origin) {
  // LOCAL
  case "http://localhost:8080":
  case "http://localhost:8081":
    globals.crmInstance = 'https://lucrmdev.crm.dynamics.com';
    globals.gatewayClientId = 'd53de251-a0cf-4808-857e-a2b70fb87f53';
    globals.aetherClient = 'b023ae3c-83c8-4ac5-927a-31ec3cc63552';
    globals.crmGatewayUrl = 'https://localhost:44301';
    globals.currentUsersAPIUrl = 'https://websvcdev.azure-api.net/currentusersapi';
    globals.currentUsersClientId = 'adaacddf-622d-43a7-8c89-43e7d383baac';
    globals.appInsights = '271d4ea1-2c54-4eab-aee5-123e6291683e';
    break;
  // DEV
  case "https://purple-moss-006a7d40f-dev.eastus2.3.azurestaticapps.net":
    globals.crmInstance = 'https://lucrmdev.crm.dynamics.com';
    globals.gatewayClientId = 'd53de251-a0cf-4808-857e-a2b70fb87f53';
    globals.aetherClient = 'b023ae3c-83c8-4ac5-927a-31ec3cc63552';
    globals.crmGatewayUrl = 'https://crmgateway2-sandbox-dev.azurewebsites.net';
    globals.currentUsersAPIUrl = 'https://websvcdev.azure-api.net/currentusersapi';
    globals.currentUsersClientId = 'adaacddf-622d-43a7-8c89-43e7d383baac';
    globals.appInsights = '271d4ea1-2c54-4eab-aee5-123e6291683e';
    break;
  // TEST
  case "https://purple-moss-006a7d40f-test.eastus2.3.azurestaticapps.net":
    globals.crmInstance = 'https://lucrmtest.crm.dynamics.com';
    globals.gatewayClientId = 'd53de251-a0cf-4808-857e-a2b70fb87f53';
    globals.aetherClient = 'b023ae3c-83c8-4ac5-927a-31ec3cc63552';
    globals.crmGatewayUrl = 'https://crmgateway2-sandbox.azurewebsites.net';
    globals.currentUsersAPIUrl = 'https://test.apis.liberty.edu/currentusersapi';
    globals.currentUsersClientId = 'adaacddf-622d-43a7-8c89-43e7d383baac';
    globals.appInsights = '271d4ea1-2c54-4eab-aee5-123e6291683e';
    break;
  // PROD
  case "https://aether.liberty.edu":
  case "https://relm.liberty.edu":
    globals.crmInstance = 'https://libertyuniv.crm.dynamics.com';
    globals.gatewayClientId = '2e243288-bc1e-4839-b527-b0c0e65e0a77';
    globals.aetherClient = '2ddbe078-a1ca-4a10-82ed-23a4a793526e';
    globals.crmGatewayUrl = 'https://crmgateway2.azurewebsites.net';
    globals.currentUsersAPIUrl = 'https://apis.liberty.edu/currentusersapi';
    globals.currentUsersClientId = '18201576-1881-48f3-b9db-740572245212';
    globals.appInsights = 'e8ecbb2a-c61a-4d76-b806-f47d72a84362';
    break;
  default:
    globals.crmInstance = "https://lucrmdev.crm.dynamics.com";
    globals.gatewayClientId = 'd53de251-a0cf-4808-857e-a2b70fb87f53';
    globals.aetherClient = 'b023ae3c-83c8-4ac5-927a-31ec3cc63552';
    globals.crmGatewayUrl = 'https://crmgateway2-sandbox-dev.azurewebsites.net';
    globals.currentUsersAPIUrl = 'https://websvcdev.azure-api.net/currentusersapi';
    globals.currentUsersClientId = 'adaacddf-622d-43a7-8c89-43e7d383baac';
    globals.appInsights = '271d4ea1-2c54-4eab-aee5-123e6291683e';
    break;
}

globals.apiUrl = `${globals.crmGatewayUrl}/crmgateway2/StudentInformationAPI`;
globals.commentsApiUrl = `${globals.crmGatewayUrl}/crmgateway2/CommentsAPI`;
globals.imageUrl = `${globals.crmGatewayUrl}/crmgateway2/ImageUrl`;
globals.aetherApiUrl = "http://localhost:7155"

const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true
};
// Application Insights Configuration
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: globals.appInsights,
    name: 'Aether',
    extensions: [clickPluginInstance],
    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig
    },
  }
});

appInsights.loadAppInsights();
appInsights.setAuthenticatedUserContext(localStorage.getItem('userName'));
appInsights.trackPageView();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
