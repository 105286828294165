<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen" :key="showTabs" :editTabOrder="editingTabOrder"
      :split-view="splitView"></vertical-nav-menu ">

    <!-- <v-app-bar app flat absolute color=" transparent"> -->
    <v-app-bar app fixed elevation="0" extension-height="64" class="app-bar">
      <!-- Left Content -->
      <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"
        color="white"></v-app-bar-nav-icon>

      <app-bar-navigation></app-bar-navigation>
      <v-spacer></v-spacer>
      <!-- <v-text-field rounded dense filled dark prepend-inner-icon="mdi-magnify" hide-details></v-text-field> -->
      <HeaderSearch />

      <v-spacer></v-spacer>
      <!-- Right Content -->
      <contact-watchers v-show="contactPage || opportunityPage" :isMenu="windowSize.x <= 750" />
      <theme-switcher v-if="windowSize.x > 750"></theme-switcher>
      <app-bar-notifications v-if="windowSize.x > 750" />
      <app-bar-user-menu v-if="windowSize.x <= 750" @toggle-edit-tabs="toggleEditing" @reload-tabs="reloadTabs"
        ref="userMenu">
        <v-divider></v-divider>
        <theme-switcher :listItem="true"></theme-switcher>
        <v-divider></v-divider>
        <app-bar-notifications :listItem="true" />
      </app-bar-user-menu>
      <app-bar-user-menu v-else @toggle-edit-tabs="toggleEditing" @reload-tabs="reloadTabs" ref="userMenu" />


      <!-- Contact Bar -->
      <template v-if="contactPage" #extension>
        <v-toolbar flat class="extension">
          <div class="boxed-container w-full">
            <div class="d-flex align-center">
              <!-- <ActivityMenu :condense="windowSize.x <= 750" /> -->
              <ActivityMenuFull :currentContact="currentContact" :currentOwner="currentOwner" :regardingObj="regarding"
                :condense="windowSize.x <= 750" />
              &nbsp;&nbsp;
              <TopLinks :condense="windowSize.x <= 750" />
              <span v-if="readonlyRecord && !showReadOnly" class="ml-2 expanding-button rounded-pill"
                :class="minimized ? 'minimized' : 'expanded'">
                <v-btn @click="!showReadOnly ? toggleReadOnlyAlert() : ''" icon x-large color="blue"
                  @mouseover="minimized = false" @mouseleave="minimized = true">
                  <v-icon color="blue">mdi-information</v-icon>
                </v-btn>
                Read Only
              </span>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="workbench-pin-button" :style="workbenchPinned ? 'width:230px' : 'width:195px'"
            @click="workbenchPinned = !workbenchPinned" v-if="appName === 'LUO' || appName === 'LUOA'">
            <div class="workbench-pin-icon"
              :style="workbenchPinned ? 'background: var(--lu-primary); color: white' : ''">
              <i class="mdi mdi-pin-off-outline" v-if="workbenchPinned"></i>
              <i class="mdi mdi-pin-outline" v-else></i>
            </div>
            <span>{{ workbenchPinned ? 'Unpin Workbench' : 'Pin Workbench' }}</span>
          </div>
        </v-toolbar>
      </template>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <v-alert v-if="(readonlyRecord || bannerDown) && showReadOnly" dense outlined prominent shaped text type="info"
          color="gray">
          This record is in Read Only mode {{ bannerDown ? ' due to a scheduled Banner Downtime' : '' }}
          <template v-slot:close>
            <v-btn icon @click="toggleReadOnlyAlert">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-alert>

        <RegistrarHeader v-if="contactPage && appName === 'Registrar'" />
        <LUOHeader v-else-if="contactPage && appName === 'LUO'" />

        <ContactHeader v-else-if="contactPage && !contactSummaryPage" />

        <PinnedWorkbenchLanding v-if="(appName === 'LUO' || appName === 'LUOA') && contactPage && workbenchPinned" />

        <v-row v-if="appName === 'Registrar' && regContainerTabs.find(t => t === registrarPage)" class="mt-15">
          <v-divider class="ml-15" style="max-width: 100px; height: 5px;"></v-divider>
          <div v-if="registrarPage === 'ActiveHolds'"
            style="margin-top: -20px; padding: 0 15px; font-size: 27px; font-weight: light;">Active Holds</div>
          <div v-else-if="registrarPage === 'RegistrationRo'"
            style="margin-top: -20px; padding: 0 15px; font-size: 27px; font-weight: light;">Registration</div>
          <div v-else style="margin-top: -20px; padding: 0 15px; font-size: 27px; font-weight: light;">{{ registrarPage
            }}
          </div>
          <v-select outlined label="Term" dense style="max-width: 130px; padding-right: 15px; margin-top: -15px;"
            :items="terms" v-model="selectedTerm" />
          <v-divider></v-divider>
          <div style="margin-top: -10px; padding: 0 15px; font-size: 15px; font-weight: light;">
            Active Term: {{ currentTerm }}
          </div>
          <v-divider class="mr-15" style="max-width: 100px;"></v-divider>
        </v-row>

        <br v-if="contactPage && !opportunityApps.find(a => a === appName)" />

        <KeepAlive>
          <router-view @update-split-view="splitView = $event" :opportunitiesLoading="!opportunityDataLoaded"
            :key="$route.path" />
        </KeepAlive>

        <TabEditSnackbar v-if="editingTabOrder" @finish-edit="finishEdit()"></TabEditSnackbar>

      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; {{ thisYear }} Liberty University CRM Team v{{ this.$store.getters.appVersion }}
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { readonly, ref } from '@vue/composition-api';
import globals from '../globals';
import {
  mdiMagnify, mdiBellOutline, mdiContentSaveOutline, mdiRefresh, mdiCheckboxMarkedCirclePlusOutline, mdiPhonePlusOutline, mdiEmailPlusOutline, mdiAccountCheckOutline, mdiAccountCancelOutline, mdiArrowLeft, mdiHistory, mdiDotsHorizontalCircleOutline
} from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/action-bar/ThemeSwitcher.vue'
import AppBarUserMenu from './components/action-bar/AppBarUserMenu.vue'
import LUOHeader from './components/headers/LUOHeader.vue'
import ContactHeader from './components/headers/ContactHeader.vue'
import { RegistrarHeader } from 'aether-registrar-summary-vue2'
import AppBarNotifications from './components/action-bar/AppBarNotifications.vue'
import AppBarNavigation from './components/action-bar/AppBarNavigation.vue'
import ContactWatchers from './components/action-bar/ContactWatchers.vue'
import TopLinks from './components/action-bar/TopLinks.vue'
// import ActivityMenu from './components/action-bar/ActivityMenu.vue';
import { ActivityMenuFull } from 'aether-activities-vue2';
import PinnedWorkbenchLanding from './components/landingPages/PinnedWorkbenchLanding.vue';
import { HeaderSearch } from 'aether-record-search-vue2';
import { TabEditSnackbar } from 'aether-user-preferences-vue2'
import moment from "moment"
import httpService from '../services/http.service';
import { defaultService, stepService } from 'aether-opportunities-vue2'
import contactService from '../services/contact.service';

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    LUOHeader,
    ContactHeader,
    RegistrarHeader,
    AppBarNotifications,
    AppBarNavigation,
    ContactWatchers,
    TopLinks,
    ActivityMenuFull,
    HeaderSearch,
    PinnedWorkbenchLanding,
    TabEditSnackbar
  },
  setup() {
    const isDrawerOpen = ref(null);

    return {
      isDrawerOpen,
      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiContentSaveOutline,
        mdiRefresh,
        mdiCheckboxMarkedCirclePlusOutline,
        mdiPhonePlusOutline,
        mdiEmailPlusOutline,
        mdiAccountCheckOutline,
        mdiAccountCancelOutline,
        mdiArrowLeft,
        mdiHistory,
        mdiDotsHorizontalCircleOutline
      },
    }
  },
  data: () => ({
    windowSize: {
      x: window.innerWidth,
      y: window.innerHeight,
    },
    contactPage: false,
    contactSummaryPage: false,
    opportunityPage: false,
    statecode: 0,
    appName: localStorage.getItem("appName"),
    workbenchPinned: false,
    readonlyRecord: localStorage.getItem('readOnly'),
    showReadOnly: true,
    minimized: true,
    bannerDown: localStorage.getItem('isbannerdown') === "true" ? true : false,
    editingTabOrder: false,
    showTabs: true,
    thisYear: moment().year(),
    terms: [],
    selectedTerm: '',
    currentTerm: '',
    registrarPage: '',
    regContainerTabs: ['Academics', 'RegistrationRo', 'Financial', 'ActiveHolds', 'Summary'],
    opportunityApps: globals.uDevApps,
    opportunityDataLoaded: false,
    splitView: sessionStorage.getItem('splitView') === 'true',
    reload: false,
    currentOwner: {},
    regarding: {},
    currentContact: {}
  }),
  async mounted() {
    this.getPageType()

    if (this.opportunityApps.find(a => a === this.appName)) {
      try {
        await this.getOpportunityData();
      } catch (err) {
        console.log(err)
      } finally {
        this.opportunityDataLoaded = true;
      }
    }

    const luidRegex = /^[L,l][0-9]{8,10}$/;
    const urlArr = window.location.pathname.split('/');
    for (var i = 0; i < urlArr.length; i++) {
      if (luidRegex.test(urlArr[i])) {
        this.contactPage = true
      }
    }

    if (window.location.pathname.includes('Registrar')) {
      let termsRes = await httpService.getFromAetherInfoApi('GET_FCI_TERMS', 'pidm', sessionStorage.getItem('pidm'));
      let futureTerms = await httpService.getFromAetherInfoApi('GET_FUTURE_TERMS');
      this.currentTerm = futureTerms.data[0].TERM
      this.terms = termsRes.data.map(t => t.TERM_CODE);
      this.selectedTerm = this.terms[0]
      for (const term of futureTerms.data) {
        if (!this.terms.find(t => t === term.TERM)) {
          this.terms.unshift(term.TERM)
        }
      }
      sessionStorage.setItem('selectedTerm', this.selectedTerm);
      let pathName = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
      this.registrarPage = pathName.substring(0, 1).toUpperCase() + pathName.substring(1)
    }

    await this.getDataForActivities();
  },

  methods: {
    refreshPage() {
      location.reload();
    },
    goBack() {
      history.back();
    },
    myEventHandler() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    toggleReadOnlyAlert() {
      this.showReadOnly = !this.showReadOnly
      this.minimized = true;
    },
    toggleEditing(isEditing) {
      this.editingTabOrder = isEditing
    },
    finishEdit() {
      this.$refs.userMenu.finishEdit()
    },
    reloadTabs() {
      //the value doesn't truly matter. Changing the value allows the component to reload
      this.showTabs = !this.showTabs;
    },
    async getOpportunityData() {
      await defaultService.getOpportunityData(this.$store);
      this.opportunityDataLoaded = true
    },
    async getDataForActivities() {
      this.currentContact = {
        pidm: sessionStorage.getItem('pidm'),
        name: sessionStorage.getItem('contactName')
      };
      this.currentOwner = {
        pidm: localStorage.getItem('userPidm'),
        name: localStorage.getItem('userFullName')
      };
      this.regarding = {
        id: sessionStorage.getItem('pidm'),
        type: 'Contact',
        name: sessionStorage.getItem('contactName')
      };
    },
    async getPageType() {
      const luidRegex = /^[L,l][0-9]{8,10}$/;
      const urlArr = window.location.pathname.split('/');
      for (var i = 0; i < urlArr.length; i++) {
        if (luidRegex.test(urlArr[i])) {
          this.contactPage = true;
          this.opportunityPage = false;
          let pidm = sessionStorage.getItem('pidm');
          if (!pidm || pidm === 'null') {
            let contact = await contactService.getPidm(urlArr[i]);
            pidm = contact.pidm;
            // contactName = contact.name;
            // sessionStorage.setItem('pidm', pidm);
            // sessionStorage.setItem('contactName', contactName);
            this.refreshPage();
          }

          break;
        } else if (urlArr[i].toLowerCase() === 'opportunities' && (urlArr[i + 1] && !Number.isNaN(urlArr[i + 1]))) {
          this.opportunityPage = true;
          this.contactPage = false;
          break;
        } else {
          this.opportunityPage = false;
          this.contactPage = false;
        }
      }
    },
    checkForSummaryInRoute(path) {
      this.contactSummaryPage = path.includes("summary");
    }
  },
  watch: {
    $route(to, from) {
      if (to.path.includes("Registrar")) {
        let pathName = to.path.substring(to.path.lastIndexOf('/') + 1)
        this.registrarPage = pathName.substring(0, 1).toUpperCase() + pathName.substring(1)
      }

      this.getPageType()
      this.checkForSummaryInRoute(to.path);
    },
    selectedTerm(newTerm) {
      sessionStorage.setItem('selectedTerm', newTerm);

      window.dispatchEvent(new CustomEvent('selected-term-changed', {
        detail: {
          storage: sessionStorage.getItem('selectedTerm')
        }
      }))
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    this.checkForSummaryInRoute(this.$route.path);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
}
</script>

<style lang="scss" scoped>
.app-bar {
  background-color: var(--lu-primary) !important;
}

.app-bar.theme--dark {
  background-color: var(--lu-charcoal-gray) !important;
}

.expanding-button {
  transition: max-width 0.5s, background-color 0.5s;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 10px;

  &.minimized {
    max-width: 40px !important;
  }

  &.expanded {
    max-width: 300px !important;
    background-color: rgb(227, 245, 255);
  }
}

.rcorners1 {
  border-radius: 25px;
  // background: #dfdfdf;
  background-color: rgba(94, 86, 105, 0.08);
  // background-color: var(--lu-darker-light-blue);
  padding: 6px;
}

.workbench-pin-button {
  display: inline-block;
  height: 40px;
  float: left;
  margin: 0 5px;
  background: rgba(94, 86, 105, 0.08);
  border-radius: 50px;
  cursor: pointer;
  // transition: all 0.3s ease-out;
  overflow: hidden;
}

// .workbench-pin-button:hover {
//   width: 190px;
// }

// .workbench-pin-button:hover .workbench-pin-icon {
//   background: var(--lu-primary);
//   color: white
// }

.workbench-pin-button .workbench-pin-icon {
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 50px;
  line-height: 40px;
  transition: all 0.3s ease-out;
}

.workbench-pin-button span {
  font-weight: 500;
  line-height: 40px;
  margin-left: 5px;
}

.workbench-pin-button:hover span {
  color: var(--lu-primary)
}


.v-app-bar ::v-deep {

  // .v-toolbar__content,
  .v-toolbar__extension {
    padding: 0px;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.extension.theme--dark {
  background-color: rgb(18, 18, 18) !important;
  border-bottom: var(--lu-charcoal-gray) 1px solid;
}

.extension.theme--light {
  border-bottom: lightgrey 1px solid;
}

.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
  color: rgba(255, 0, 0, 0.33);
}

.boxed-container {
  // max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.searchBar {
  background-color: var(--lu-primary);
}
</style>
