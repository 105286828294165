import { render, staticRenderFns } from "./AppBarUserMenu.vue?vue&type=template&id=80543eea&scoped=true"
import script from "./AppBarUserMenu.vue?vue&type=script&lang=js"
export * from "./AppBarUserMenu.vue?vue&type=script&lang=js"
import style0 from "./AppBarUserMenu.vue?vue&type=style&index=0&id=80543eea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80543eea",
  null
  
)

export default component.exports