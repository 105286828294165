<template>
    <div>
        <v-menu v-if="condense" left nudge-bottom="45" min-width="230" content-class="activity-menu-content">
            <template v-slot:activator="{ on: onMenu, attrs }">
                <span v-bind="attrs" v-on="onMenu">
                    <v-icon>mdi-file-document-edit-outline</v-icon>
                    <span class="ml-2">Actions</span>
                </span>
            </template>
            <v-list style="background-color: var(--lu-primary); width: fit-content;">
                <div v-for="(link, index) in links" :key="index">
                    <v-divider color="white" style="opacity: 0.3;"></v-divider>
                    <v-list-item class="mt-1" @click="handleClick(link)" style="margin-top: -1rem;">
                        <v-icon class="mr-2" color="white">{{ link.icon }}</v-icon>
                        <div style="color: white">{{ link.label }}</div>
                    </v-list-item>
                </div>
            </v-list>
        </v-menu>
        <v-menu v-else-if="links.find(l => l.show === true)">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">
                    <iconify-icon icon="solar:menu-dots-outline"></iconify-icon>
                </v-btn>
            </template>
            <v-list v-for="(link, index) in links" :key="index" v-show="link.show">
                <v-list-item link @click="handleClick(link)">
                    <v-list-item-title >{{ link.label }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-snackbar :color="snackbarColor" top v-model="snackbar" :timeout="timeout">
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn color="var(--lu-light-gray)" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <v-dialog transition="dialog-top-transition" v-model="dialog" persistent max-width="500">
            <v-card>
                <v-card-title class="text-h5">
                    Contact Threatened Legal Action
                </v-card-title>
                <v-card-text>Are you sure you want to mark contact as 'Threatened Legal Action'?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="var(--lu-red)" text @click="dialog = false">
                        Disagree
                    </v-btn>
                    <v-btn color="var(--lu-secondary)" :loading="loadingLAT" text
                        @click="legalActionThreatenedApproved()">
                        Agree
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import globals from '@/globals.js';
import customConfigService from '../../../services/customConfig.service';
import contactService from '../../../services/contact.service';
import httpService from '../../../services/http.service';
import userService from '../../../services/user.service';
import moment from 'moment';

export default {
    name: "ContactUrlMenu",
    props: {
        condense: Boolean,
    },
    data() {
        return {
            dialog: false,
            loadingLAT: false,
            snackbar: false,
            snackbarMessage: '',
            snackbarColor: 'var(--lu-primary)',
            timeout: 2000,
            etrieveUrl: '',
            selfCertUrl: '',
            pacId: '',
            roles: [],
            appName: localStorage.getItem("appName"),
            pacUser: false,
            legalAction: false,
            selfCertUser: false,
            academicPlanSheetUser: false,
            etrieveUser: false
        }
    },
    async mounted() {
        try {
            this.roles = await userService.getUserRoles();
            if (this.roles.includes('Paciolan User')) {
                this.pacId = await contactService.getPacId();
                this.pacUser = true;
            }
            if (this.roles.includes('Etrieve User')) {
                this.etrieveUrl = await customConfigService.getEtrieveUrl();
                this.etrieveUser = true;
            }
            if (this.roles.includes('Self-Cert User')) {
                this.selfCertUrl = await customConfigService.getSelfCertUrl();
                this.selfCertUser = true;
            }
            if (this.roles.includes('Academic Planning Sheet User')) {
                this.academicPlanSheetUser = true;
            }
            if (this.roles.includes('Legal Action Threatened')) {
                this.legalAction = true;
            }

        } catch (error) {
            this.error = true;
        } finally {
            this.loading = false;
        }
    },
    computed: {
        links: function () {
            const instance = globals.crmInstance;
            const libertyId = sessionStorage.getItem('libertyId');
            const appName = localStorage.getItem("appName")

            const getUrlVariation = baseUrls => {
                let url;
                if (instance === "https://lucrmtest.crm.dynamics.com") {
                    url = baseUrls.test;
                } else if (instance === "https://libertyuniv.crm.dynamics.com") {
                    url = baseUrls.prod;
                } else {
                    // Defaulting to dev if instance isnt test or prod
                    url = baseUrls.dev;
                }
                return url;
            };

            const linkArray = [
                {
                    label: "Paciolan",
                    url: `https://client.paciolan.com/op/patron/Account?id=${this.pacId}`,
                    target: "_blank",
                    icon: "mdi-alpha-p-box-outline",
                    show: this.pacUser
                },
                {
                    label: "Etrieve",
                    url: this.etrieveUrl,
                    target: "_blank",
                    icon: "mdi-file-document-multiple-outline",
                    show: this.etrieveUser
                },
                {
                    label: "Academic Planning Sheet",
                    url: getUrlVariation({
                        dev: `https://crmgateway2-sandbox-dev.azurewebsites.net/crmgateway2/files/common/views/successPlan.html?libertyid=${libertyId}_self`,
                        test: `https://crmgateway2-sandbox.azurewebsites.net/crmgateway2/files/common/views/successPlan.html?libertyid=${libertyId}_self`,
                        prod: `https://crmgateway2.azurewebsites.net/crmgateway2/files/common/views/successPlan.html?libertyid=${libertyId}_self`
                    }),
                    target: "_blank",
                    icon: "mdi-file-document-plus-outline",
                    show: this.academicPlanSheetUser
                },
                {
                    label: "Self Cert",
                    url: this.selfCertUrl,
                    target: "_blank",
                    icon: "mdi-file-certificate-outline",
                    show: this.selfCertUser
                },
                {
                    label: "Legal Action Threatened",
                    icon: "mdi-scale-balance",
                    show: this.legalAction
                },
                {
                    label: "New Contact",
                    url: `/${appName}/new-contact`,
                    target: "_self",
                    icon: "mdi-file-certificate-outline",
                    show: true
                }
            ];
            return linkArray;
        }
    },
    methods: {
        async legalActionThreatenedApproved() {
            // Only get here if dialog is approved
            try {
                this.loadingLAT = true;
                await httpService.getFromAetherInfoApi('SET_LEGAL_ACTION_APPROVED', 'pidm,date', `${sessionStorage.getItem('pidm')},${moment().format('DD-MMM-YY')}`)
                this.snackbar = true;
                this.snackbarMessage = 'Legal action threatened status updated successfully';
                this.snackbarColor = 'var(--lu-secondary)';

                this.loadingLAT = false;
                this.dialog = false;
            } catch (error) {
                this.snackbar = true;
                this.snackbarMessage = 'Error updating legal action threatened status';
                this.snackbarColor = 'var(--lu-red)';
                console.error(error);
                this.loadingLAT = false;
                this.dialog = false;
            }
        },
        async handleClick(link) {
            if (link.label === "Legal Action Threatened") {
                this.dialog = true;
            } else {
                window.open(link.url, link.target);
            }
        }
    }
};
</script>



<style lang="scss" scoped>
.rcorners1 {
    border-radius: 25px;
    // background: #dfdfdf;
    background-color: rgba(94, 86, 105, 0.08);
    padding: 6px;
}

.v-app-bar ::v-deep {
    .v-toolbar__content {
        padding: 0;

        .app-bar-search {
            .v-input__slot {
                padding-left: 18px;
            }
        }
    }
}

.boxed-container {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}
</style>
