<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content"
    :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-badge v-if="showNotifications" bordered top overlap color="#990000" offset-x="12" offset-y="18"
        :content="notificationCount" :value="notificationCount">
        <div>
          <v-badge bottom overlap dot :color="presenceColor(userInfo.presence)" offset-x="12" offset-y="12"
            class="ms-4">
            <v-avatar size="40px" v-bind="attrs" v-on="on">
              <ProfileAvatar :avatar="userInfo"></ProfileAvatar>
            </v-avatar>
          </v-badge>
        </div>
      </v-badge>
      <v-badge v-else bottom overlap dot :color="presenceColor(userInfo.presence)" offset-x="12" offset-y="12"
        class="ms-4">
        <v-avatar size="40px" v-bind="attrs" v-on="on">
          <ProfileAvatar :avatar="userInfo"></ProfileAvatar>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge bottom :color="presenceColor(userInfo.presence)" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px">
            <ProfileAvatar :avatar="userInfo"></ProfileAvatar>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align:middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userInfo.name }}
          </span>
          <small class="text--disabled text-capitalize">{{ userInfo.jobTitle }}</small>
          <!-- <small class="text--disabled text-capitalize">{{ businessUnit }}</small> -->
        </div>
      </div>
      <slot></slot>
      <v-divider></v-divider>
      <PreferenceMenu @toggle-edit-tabs="toggleEditing" @update-tabs="updateTabs" ref="preferenceMenu" />
      <!-- <span v-show="visibilityOptions.length > 1" class="pb-3 pt-2">
        <v-divider></v-divider>
        <v-select label="Select App" dense outlined :items="visibilityOptions" v-model="appSelected"
          style="margin: 15px 5px -8px 5px; width: 175px !important;" />
      </span> -->
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import ThemeSwitcher from './ThemeSwitcher.vue';
import ProfileAvatar from './profile/ProfileAvatar.vue';
import notificationService from '../../../services/notification.service';
import viewingUsersService from '../../../services/viewingUsers.service';

import { PreferenceMenu, TabEditSnackbar } from 'aether-user-preferences-vue2'
import { tabService } from "aether-tabcontainers-vue2"

export default {
  components: { ThemeSwitcher, ProfileAvatar, PreferenceMenu, TabEditSnackbar },
  expose: ['finishEdit'],
  props: {
    user: Object,
    width: Number,
    displayTooltip: Boolean,
    showNotifications: Boolean
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  data() {
    return {
      userInfo: {},
      // businessUnit: '',
      background: "white",
      text: "black",
      // visibilityOptions: localStorage.getItem("userVisibility").split(','),
      // appSelected: localStorage.getItem("appName"),
      notificationCount: 0,
    }
  },
  async mounted() {
    // if (this.visibilityOptions.length >= 1 && !localStorage.getItem("appName")) {
    //   localStorage.setItem("appName", this.visibilityOptions[0])
    // } else if (!localStorage.getItem("appName")) {
    //   localStorage.setItem("appName", 'LUO')
    // }

    try {
      this.userInfo = await viewingUsersService.fetchCurrentUser();
      window.localStorage.setItem('userFullName', this.userInfo.name);
      // this.businessUnit = await userService.fetchBusinessUnit(this.$store);
      const notifications = await notificationService.fetchNotifications();
      this.notificationCount = notifications.length;
    } catch (error) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    presenceColor(presence) {
      switch (presence) {
        case 'Away':
        case 'BeRightBack':
          return 'yellow'
        case 'Busy':
        case 'BusyIdle':
        case 'DoNotDisturb':
          return 'red'
        case 'Offline':
        case 'PresenceUnknown':
          return 'grey'
        case 'Available':
        case 'AvailableIdle':
          return 'success'
        default:
          return 'grey'
      }
    },
    async toggleEditing(editing) {
      const isEditing = editing.isEditing;
      this.$emit('toggle-edit-tabs', isEditing)
      await tabService.postNewOrders(editing.postType);
    },
    updateTabs() {
      tabService.loadTabs();
      this.$emit('reload-tabs')
    },
    finishEdit() {
      this.$refs.preferenceMenu.editTabOrder = false
    }
  },
  watch: {
    // appSelected(newSelected, oldSelected) {
    //   localStorage.setItem("appName", newSelected)
    //   location.reload();
    // }
  }
}
</script>

<style lang="scss" scoped>
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}

.image {
  width: 100%;
  height: 100%;
}
</style>
